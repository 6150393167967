import {
  ArrowLeftStartOnRectangleIcon,
  UserPlusIcon,
} from '@heroicons/react/20/solid';
import {
  ArrowRightEndOnRectangleIcon,
  UserIcon,
} from '@heroicons/react/16/solid';
import {Link, useLocation, useRouteLoaderData} from 'react-router';
import {Popover, PopoverContent, PopoverTrigger} from '~/components/ui/popover';
import {useEffect, useMemo, useState} from 'react';

import {Button} from '~/components/ui/button';
import {Scramble} from './scramble';
import {cn} from '~/utils';

export const Intro = () => {
  const lines = useMemo(() => {
    return [
      `███████╗██████╗ ██╗ ██████╗     ██████╗  █████╗ ███╗   ███╗███████╗         ██╗ █████╗ ███╗   ███╗`,
      `██╔════╝██╔══██╗██║██╔════╝    ██╔════╝ ██╔══██╗████╗ ████║██╔════╝         ██║██╔══██╗████╗ ████║`,
      `█████╗  ██████╔╝██║██║         ██║  ███╗███████║██╔████╔██║█████╗           ██║███████║██╔████╔██║`,
      `██╔══╝  ██╔═══╝ ██║██║         ██║   ██║██╔══██║██║╚██╔╝██║██╔══╝      ██   ██║██╔══██║██║╚██╔╝██║`,
      `███████╗██║     ██║╚██████╗    ╚██████╔╝██║  ██║██║ ╚═╝ ██║███████╗    ╚█████╔╝██║  ██║██║ ╚═╝ ██║`,
      `╚══════╝╚═╝     ╚═╝ ╚═════╝     ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝╚══════╝     ╚════╝ ╚═╝  ╚═╝╚═╝     ╚═╝`,
    ];
  }, []);

  return (
    <div
      key="egj"
      aria-label="Epic Game Jam"
      title="Epic Game Jam"
      className="mx-auto flex select-none flex-col items-start text-[1.45vw] leading-[1.1] text-accent lg:text-[min(1.6vw,21px)]"
    >
      {lines.map((line, i) => (
        <Link to="/" key={i} aria-hidden={true}>
          <pre>
            <Scramble>{line}</Scramble>
          </pre>
        </Link>
      ))}
    </div>
  );
};

export const UserDropdown = () => {
  const data = useRouteLoaderData('root');
  const {account} = data || {};
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="group inline-flex max-w-[30ch] items-center gap-1 overflow-hidden rounded px-1 hover:bg-background hover:text-primary">
        {account ? (
          <>
            {account.picture ? (
              <img
                src={account.picture}
                alt={account.email}
                className="-my-1 size-6 rounded-full border border-black/20 mix-blend-luminosity group-hover:border-black/40 group-hover:mix-blend-normal"
              />
            ) : (
              <UserIcon className="size-5" />
            )}
            <span className="truncate">{account.email}</span>
          </>
        ) : (
          <>
            <UserIcon className="size-5" />
            <span className="truncate">Login / Sign up</span>
          </>
        )}
      </PopoverTrigger>
      <PopoverContent className={cn(!account && 'w-96')} align="end">
        {account ? (
          <div className="mx-auto flex flex-col items-center gap-4 text-center">
            {account.picture && (
              <img
                className="size-20 rounded-full"
                src={account.picture}
                alt={account.email}
              />
            )}
            <p>
              Welcome, <strong>{account.given_name}</strong>!
            </p>
            <div className="flex w-full flex-col gap-3">
              {account.username && (
                <Button variant="outline" asChild>
                  <Link to={`/jammers/${account.username}`}>
                    My Epic Jammer Page
                  </Link>
                </Button>
              )}
              <Button variant="destructive" asChild>
                <Link to="/kinde-auth/logout">
                  <ArrowLeftStartOnRectangleIcon className="size-5" />
                  <span>Logout</span>
                </Link>
              </Button>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <div className="flex w-full flex-col gap-3">
              <Button variant="outline" asChild>
                <Link
                  to={{
                    pathname: '/kinde-auth/register',
                    search: '?org_code=org_ad433b348a6e',
                  }}
                >
                  <UserPlusIcon className="size-5" />
                  <span>Sign up</span>
                </Link>
              </Button>
              <Button asChild>
                <Link
                  to={{
                    pathname: '/kinde-auth/login',
                    search: '?org_code=org_ad433b348a6e',
                  }}
                >
                  <ArrowRightEndOnRectangleIcon className="size-5" />
                  <span>Login</span>
                </Link>
              </Button>
            </div>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};

export const Header = () => {
  return (
    <header className="sticky top-0 z-50 -mx-2 mb-4">
      <div className="flex h-9 justify-between gap-4 bg-primary px-2 py-1 font-title text-xl text-background">
        <Link to="/">
          <Scramble className="hidden sm:inline">EpicGameJam.x24_x10</Scramble>
          <Scramble className="sm:hidden">EGJ.x24_x10</Scramble>
        </Link>
        <UserDropdown />
      </div>
      <div className="absolute top-full z-10 w-full">
        <div className="mt-px border-b-4 border-primary" />
        <div className="mt-[2px] border-b-[3px] border-primary" />
        <div className="mt-[3px] border-b-2 border-primary" />
        <div className="mt-[4px] border-b border-primary" />
      </div>
    </header>
  );
};
